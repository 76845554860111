import { userRoles } from '../modules/auth/constants';

const getUserCompanies = (user) => {
  switch (user.role) {
    case userRoles.admin:
      return user.admin_companies;
    case userRoles.partner:
      return user.partner_company.companies;
    case userRoles.client:
      return [user.company];
    case userRoles.parter_admin:
      return [user.company];
    case userRoles.company_admin:
      return [user.company];
    default:
      return [user.company];
  }
};

export default getUserCompanies;
