import React, { useRef, useCallback, useState, useEffect } from 'react';
import { Button, CircularProgress, FormHelperText, makeStyles, Typography } from '@material-ui/core';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import { onDrop, onInput } from 'modules/workLook/components/UploadCsvModal/utils';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Loader } from 'components';
import theme from './UploadCSV.module.scss';

// eslint-disable-next-line no-shadow
const useStyles = makeStyles((theme) => ({
  dropzonesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: '3%',
    justifyContent: 'center',
    flexDirection: 'column',
    alignContent: 'center',
  },
  dropzoneContainer: { display: 'flex', flexDirection: 'column', alignItems: 'center', width: '500px' },
  innerDropzoneContainer: { display: 'flex', flexDirection: 'column', alignItems: 'center', width: '460px' },
  helperText: {
    marginTop: 0,
    fontSize: '20px',
  },
  dropzoneHeader: { fontWeight: 'bold' },
  dropzone: {
    width: 'calc(100% - 15px)',
    height: '200px',
    paddingTop: '20px',
    margin: '10px',
    display: 'flex',
    flexWrap: 'wrap',
    border: '1px dashed',
    boxSizing: 'border-box',
    borderRadius: '4px',
  },
  errorContainer: { height: '65px', overflow: 'auto' },
  errorMessage: {
    color: theme.palette.error.main,
  },
  dropzoneIcon: {
    margin: '0 auto',
    width: '61px',
    height: '40px',
    color: theme.palette.grey[400],
  },
  dropzoneText: {
    fontFamily: theme.typography.fontFamily,
    flexBasis: '100%',
    textAlign: 'center',
  },
  error: {
    color: 'red',
  },
}));

const UploadCSV = ({ formik, data, setOpen, open, isLoading, errors }) => {
  const classes = useStyles();
  const [dataTables, setDataTables] = useState([]);
  useEffect(() => {
    console.log('data = ', data);
    setDataTables((prev) => [...data, { id: 1, name: 'Additional table', isOpen: false }]);
  }, [data]);

  const toggleFiledItem = (index) => {
    setDataTables((prev) =>
      prev.map((table, indexTable) => {
        return { ...table, isOpen: index === indexTable ? !table.isOpen : false };
      }),
    );
  };

  const handleAddTable = () => {
    setDataTables((prev) => [...prev, { id: prev.length, name: `Additional table${prev.length}`, isOpen: false }]);
  };

  const inputField = useRef([dataTables]);

  const onButtonClick = useCallback((index) => {
    if (!inputField.current[index]) {
      return;
    }
    inputField.current[index].click();
  }, []);

  return (
    <>
      <div className={theme.container}>
        <div className={classes.dropzonesContainer}>
          {dataTables.map(({ id, name, isOpen }, index) => {
            return (
              <>
                <If condition={index === 0}>
                  <div key={id} className={classes.dropzoneContainer}>
                    <div className={theme.helperTextContainer}>
                      <FormHelperText component="div" className={classes.helperText} error={!!formik.errors[id]}>
                        <Choose>
                          <When condition={!!formik.errors[id]}>
                            <If condition={Array.isArray(formik.errors[id])}>
                              <div className={classes.errorContainer}>
                                {formik.errors[id].map((errorMessage) => (
                                  <p className={classes.errorMessage} key={errorMessage}>
                                    {errorMessage}
                                  </p>
                                ))}
                              </div>
                              <Else />
                              {formik.errors[id]}
                            </If>
                          </When>
                          <When condition={formik.values.files.some((el) => Number(el.id) === id)}>
                            {formik.values.files.find((el) => Number(el.id) === id).file.name}
                          </When>
                        </Choose>
                      </FormHelperText>
                    </div>
                    {errors?.map((error) => (
                      <Typography variant="body2" className={classes.error}>
                        {error}
                      </Typography>
                    ))}
                    <h1 className={classes.dropzoneHeader}>{name}</h1>
                    <div
                      onDragOver={(e) => {
                        e.preventDefault();
                      }}
                      onDrop={(e) => onDrop(e, formik, id)}
                      className={classes.dropzone}
                      style={!formik.errors[id] ? { borderColor: 'gray' } : { borderColor: 'red' }}
                    >
                      <CloudUploadOutlinedIcon className={classes.dropzoneIcon} />
                      <div className={classes.dropzoneText}>
                        Drag file here or
                        <input
                          disabled={isLoading}
                          type="file"
                          name="uploadCsvFile"
                          ref={(el) => {
                            inputField.current[index] = el;
                          }}
                          accept="text/csv"
                          multiple={false}
                          style={{ display: 'none' }}
                          onInput={(e) => onInput(e, formik, id)}
                        />
                        <Button
                          disabled={isLoading}
                          color="primary"
                          component="span"
                          onClick={() => onButtonClick(index)}
                        >
                          BROWSE
                        </Button>
                      </div>
                    </div>
                  </div>
                </If>
                <If condition={index !== 0}>
                  <div className={theme.advanceSelector} style={{ height: isOpen ? '320px' : '50px' }}>
                    <div className={theme.selectorHeader} onClick={() => toggleFiledItem(index)}>
                      <Typography>Advanced - Add Additional CSV File</Typography>
                      {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </div>
                    <If condition={isOpen}>
                      <div key={id} className={classes.innerDropzoneContainer}>
                        <div className={theme.helperTextContainer}>
                          <FormHelperText component="div" className={classes.helperText} error={!!formik.errors[id]}>
                            <Choose>
                              <When condition={!!formik.errors[id]}>
                                <If condition={Array.isArray(formik.errors[id])}>
                                  <div className={classes.errorContainer}>
                                    {formik.errors[id].map((errorMessage) => (
                                      <p className={classes.errorMessage} key={errorMessage}>
                                        {errorMessage}
                                      </p>
                                    ))}
                                  </div>
                                  <Else />
                                  {formik.errors[id]}
                                </If>
                              </When>
                              <When condition={formik.values.files.some((el) => Number(el.id) === id)}>
                                {formik.values.files.find((el) => Number(el.id) === id).file.name}
                              </When>
                            </Choose>
                          </FormHelperText>
                        </div>
                        {errors?.map((error) => (
                          <Typography variant="body2" className={classes.error}>
                            {error}
                          </Typography>
                        ))}
                        <h1 className={classes.dropzoneHeader}>{name}</h1>
                        <div
                          onDragOver={(e) => {
                            e.preventDefault();
                          }}
                          onDrop={(e) => onDrop(e, formik, id)}
                          className={classes.dropzone}
                          style={!formik.errors[id] ? { borderColor: 'gray' } : { borderColor: 'red' }}
                        >
                          <CloudUploadOutlinedIcon className={classes.dropzoneIcon} />
                          <div className={classes.dropzoneText}>
                            Drag file here or
                            <input
                              disabled={isLoading}
                              type="file"
                              name="uploadCsvFile"
                              ref={(el) => {
                                inputField.current[index] = el;
                              }}
                              accept="text/csv"
                              multiple={false}
                              style={{ display: 'none' }}
                              onInput={(e) => onInput(e, formik, id)}
                            />
                            <Button
                              disabled={isLoading}
                              color="primary"
                              component="span"
                              onClick={() => onButtonClick(index)}
                            >
                              BROWSE
                            </Button>
                          </div>
                        </div>
                      </div>
                    </If>
                  </div>
                </If>
              </>
            );
          })}
          <Button
            disabled={isLoading}
            className={theme.sbmtBtn}
            onClick={handleAddTable}
            color="primary"
            variant="outlined"
          >
            Add table
          </Button>
        </div>
      </div>
      <div className={theme.btnWrapper}>
        <Button
          disabled={isLoading}
          className={theme.sbmtBtn}
          onClick={formik.handleSubmit}
          color="primary"
          variant="outlined"
          type="submit"
        >
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <If condition={isLoading}>
              <CircularProgress style={{ marginRight: '4px' }} size={16} />
            </If>
            Submit
          </div>
        </Button>
      </div>
    </>
  );
};

export default UploadCSV;
