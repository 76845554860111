export default {
  DIALOG: 'DIALOG',
  AL_FILTER_MODAL: 'AL_FILTER_MODAL',
  AL_ASSET_MNGMNT: 'AL_ASSET_MNGMNT',
  AL_REQUEST_ORDER: 'AL_REQUEST_ORDER',
  AL_REQUEST_COMPLETE: 'AL_REQUEST_COMPLETE',
  AL_ARRIVAL_ORDER_UPLOAD: 'AL_ARRIVAL_ORDER_UPLOAD',
  WL_FILTER_MODAL: 'WL_FILTER_MODAL',
  WL_MAP_MODAL: 'WL_MAP_MODAL',
  LL_FILTER_MODAL: 'LL_FILTER_MODAL',
  WL_GRAPH_MODAL: 'WL_GRAPH_MODAL',
  WL_MODAL_DETAILS: 'WL_MODAL_DETAILS',
  WL_EDIT_GRAPH_MODAL: 'WL_EDIT_GRAPH_MODAL',
  WL_MODIFIERS_GRAPH_MODAL: 'WL_MODIFIERS_GRAPH_MODAL',
  WL_CREATE_PAGE_MODAL: 'WL_CREATE_PAGE_MODAL',
  WL_CREATE_SECTION_MODAL: 'WL_CREATE_SECTION_MODAL',
  WL_CREATE_TEMPLATE_MODAL: 'WL_CREATE_TEMPLATE_MODAL',
  WL_CREATE_BLANK_REPORT: 'WL_CREATE_BLANK_REPORT',
  WL_EDIT_REPORTS_FIELDS: 'WL_EDIT_REPORTS_FIELDS',
  WL_EDIT_TEMPLATES: 'WL_EDIT_TEMPLATES',
  USER_SETTINGS: 'USER_SETTINGS',
  COMPANY_SETTIINGS: 'COMPANY_SETTIINGS',
  EDIT_COMPANY: 'EDIT_COMPANY',
  CONFIRM_DELETION: 'CONFIRM_DELETION',
  ERROR: 'ERROR',
  RESET_PASSWORD: 'RESET_PASSWORD',
};
