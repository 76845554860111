// eslint-disable-next-line consistent-return
const validateActiveCompany = (user, companyId) => {
  // eslint-disable-next-line default-case
  switch (user.role) {
    case 'admin':
      return Boolean(user.admin_companies.find(({ id }) => id === companyId));
    case 'partner':
      return Boolean(user.partner_company.companies.find(({ id }) => id === companyId));
    case 'client':
      return user.company.id === companyId;
    case 'company_admin':
      return user.company.id === companyId;
    case 'partner_admin':
      return user.company.id === companyId;
  }
};

export default validateActiveCompany;
