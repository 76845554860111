/* eslint-disable */
const findActiveCompany = (user) => {
  switch (user.role) {
    case 'admin':
      return user.admin_companies[0].id;
    case 'partner':
      return user.partner_company.companies[0].id;
    case 'client':
      return user.company.id;
    case 'company_admin':
      return user.company.id;
    case 'partner_admin':
      return user.company.id;
  }
};

export default findActiveCompany;
