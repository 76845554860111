const BASE = process.env.REACT_APP_API_URL || 'https://api.dev.bakerstuart.wesoftyou.club';
const VERSION = '/v1';
const SURVEY = '/survey';
const COMBINED_OCCUPANCY = '/combined-occupancy';
const DESK_UTILIZATION = '/desk-utilization';
const DESK_WEEKDAY_AND_TIME = '/desk-weekday-and-time';
const DESK_HIERARCHY = '/desk-hierarchy';
const DESK_USAGE_BANDING = '/desk-usage-bading';
const DESK_AGILE = '/desk-agile';
const HISTORIC = '/historic';
const MEETING_UTILIZATION = '/meeting-space-utilization';
const MEETING_BUILDING_FLOOR = '/meeting-building-and-floor';
const MEETING_ROOM_TYPE = '/meeting-room-type';
const TYPES = {
  meet: '/?type=Meet',
  breakout: '/?type=Breakout',
};
const PEOPLE_LOOK = '/people-look';
const ASSET_LOOK = '/asset-look';
const LIVE_LOOK = '/live-look';
const AUTH = '/auth';

const ENDPOINTS = {
  auth: {
    login: `${BASE}${VERSION}${AUTH}/login/`,
    otpDisable: `${BASE}${VERSION}${AUTH}/otp/delete/`,
    user: `${BASE}${VERSION}/user/`,
    companyList: `${BASE}${VERSION}/company/company-list`,
    partnerCompanyList: `${BASE}${VERSION}/company/partner-company-list`,
    updateUser: `${BASE}${VERSION}/user/:user_id`,
    companyUsers: `${BASE}${VERSION}/user/:companyId/list`,
    deleteUser: `${BASE}${VERSION}/user/:userId`,
    resetPassword: `${BASE}${VERSION}/user/change-password/`,
    companyData: `${BASE}${VERSION}/company/:companyId/`,
  },

  shared: {
    propertyNamesMap: `${BASE}${VERSION}/property/names-mapping/`,
    version: `${BASE}${VERSION}/version/`,
    portalSections: `${BASE}${VERSION}/user/portal-sections?company_id=:getSections`,
  },

  // SPACE LOOK
  reportInfo: `${BASE}${VERSION}${SURVEY}/:id/report-title-info/`,
  surveyDetails: `${BASE}${VERSION}${SURVEY}/:id/details`,
  combinedDataboxes: `${BASE}${VERSION}${SURVEY}/:id${COMBINED_OCCUPANCY}/data-blocks`,
  combinedDailyHourlyGraph: `${BASE}${VERSION}${SURVEY}/:id${COMBINED_OCCUPANCY}/hourly-graph`,
  combinedText: `${BASE}${VERSION}${SURVEY}/:id${COMBINED_OCCUPANCY}/text-data`,

  desksDataboxes: `${BASE}${VERSION}${SURVEY}/:id${DESK_UTILIZATION}/result-databoxes`,
  desksPie: `${BASE}${VERSION}${SURVEY}/:id${DESK_UTILIZATION}/result-diagram-data`,
  desksText: `${BASE}${VERSION}${HISTORIC}/text-data`,
  desksDailyHourlyGraph: `${BASE}${VERSION}${SURVEY}/:id${DESK_UTILIZATION}/result-hourly-graph`,
  desksWeeklyHourlyGraph: `${BASE}${VERSION}${SURVEY}/:id${DESK_WEEKDAY_AND_TIME}/weekday-hourly-graph`,
  desksUsageBandingOccGraph: `${BASE}${VERSION}${SURVEY}/:id${DESK_USAGE_BANDING}/occupancy-graph-data`,
  desksUsageBandingUtilGraph: `${BASE}${VERSION}${SURVEY}/:id${DESK_USAGE_BANDING}/utilization-graph-data`,
  desksWeeklyPeakAndAvgGraph: `${BASE}${VERSION}${SURVEY}/:id${DESK_WEEKDAY_AND_TIME}/weekday-totals-graph`,
  desksByBuildingAndFloor: `${BASE}${VERSION}${SURVEY}/:id/desk-floor-and-property`,
  desksDivision: `${BASE}${VERSION}${SURVEY}/:id${DESK_HIERARCHY}/division`,
  desksDepartment: `${BASE}${VERSION}${SURVEY}/:id${DESK_HIERARCHY}/department`,
  desksTypeGraph: `${BASE}${VERSION}${SURVEY}/:id/desk-utilization-category1/result-diagram-data`,
  desksAgilityGraph: `${BASE}${VERSION}${SURVEY}/:id${DESK_AGILE}/division-graph-data`,
  desksAgilityDataboxes: `${BASE}${VERSION}${SURVEY}/:id${DESK_AGILE}/key-data`,
  desksHistoricData: `${BASE}${VERSION}${HISTORIC}/:id/historic-data`,

  meetingsDataboxes: `${BASE}${VERSION}${SURVEY}/:id${MEETING_UTILIZATION}/key-data-blocks${TYPES.meet}`,
  meetingsPie: `${BASE}${VERSION}${SURVEY}/:id${MEETING_UTILIZATION}/result-diagram${TYPES.meet}`,
  meetingsComparisonText: `${BASE}${VERSION}${SURVEY}/:id${MEETING_ROOM_TYPE}/space-comparison-text${TYPES.meet}`,
  meetingsDailyHourlyGraph: `${BASE}${VERSION}${SURVEY}/:id${MEETING_UTILIZATION}/result-hourly-graph${TYPES.meet}`,
  meetingsFloorUtil: `${BASE}${VERSION}${SURVEY}/:id${MEETING_BUILDING_FLOOR}/utilization-by-floor-graph${TYPES.meet}`,
  meetingsBuildingUtil: `${BASE}${VERSION}${SURVEY}/:id${MEETING_BUILDING_FLOOR}/utilization-by-building-graph${TYPES.meet}`,
  meetingsPeakAndAverageUtil: `${BASE}${VERSION}${SURVEY}/:id${MEETING_ROOM_TYPE}/utilization-meeting-rooms${TYPES.meet}`,
  meetingsObservedSizes: `${BASE}${VERSION}${SURVEY}/:id/meeting-room-observed-sizes/graph-sizes-utilization${TYPES.meet}`,

  breakoutDataboxes: `${BASE}${VERSION}${SURVEY}/:id${MEETING_UTILIZATION}/key-data-blocks${TYPES.breakout}`,
  breakoutPie: `${BASE}${VERSION}${SURVEY}/:id${MEETING_UTILIZATION}/result-diagram${TYPES.breakout}`,
  breakoutText: `${BASE}${VERSION}${SURVEY}/:id/breakout-key-findings-text`,
  breakoutDailyHourlyGraph: `${BASE}${VERSION}${SURVEY}/:id${MEETING_UTILIZATION}/result-hourly-graph${TYPES.breakout}`,
  breakoutFloorUtil: `${BASE}${VERSION}${SURVEY}/:id${MEETING_BUILDING_FLOOR}/utilization-by-floor-graph${TYPES.breakout}`,
  breakoutBuildingUtil: `${BASE}${VERSION}${SURVEY}/:id${MEETING_BUILDING_FLOOR}/utilization-by-building-graph${TYPES.breakout}`,
  breakoutPeakAndAverageUtil: `${BASE}${VERSION}${SURVEY}/:id${MEETING_ROOM_TYPE}/utilization-meeting-rooms${TYPES.breakout}`,

  appliedFilters: `${BASE}${VERSION}/filter-history/`,
  combinedInitFilters: `${BASE}${VERSION}${SURVEY}/:id${COMBINED_OCCUPANCY}/filter-initial-values/`,
  desksInitFilters: `${BASE}${VERSION}${SURVEY}/:id/desk-filter-initial-values/`,
  benchmarkingInitFilters: `${BASE}${VERSION}${HISTORIC}/:id/filter-initial-values/`,
  meetingsInitFilters: `${BASE}${VERSION}${SURVEY}/:id/meet-filter-initial-values${TYPES.meet}`,
  breakoutInitFilters: `${BASE}${VERSION}${SURVEY}/:id/meet-filter-initial-values${TYPES.breakout}`,

  heatmaps: `${BASE}${VERSION}${SURVEY}/:id/heatmaps/`,

  basePdfReport: `${BASE}${VERSION}${SURVEY}/:id/report/`,
  customPdfReport: `${BASE}${VERSION}${SURVEY}/:id/custom-pdf-report/`,

  workLook: {
    reports: `${BASE}${VERSION}${PEOPLE_LOOK}/reports/:companyId/type/:typeId/`,
    graph: `${BASE}${VERSION}${PEOPLE_LOOK}/data/graph/:graphId/`,
    graphText: `${BASE}${VERSION}${PEOPLE_LOOK}/reports/graph/description/:graphId/`,
    counter: `${BASE}${VERSION}${PEOPLE_LOOK}/reports/count-data/:reportId/`,
    basket: `${BASE}${VERSION}${PEOPLE_LOOK}/data/report-basket/:reportId/`,
    baseReport: `${BASE}${VERSION}${PEOPLE_LOOK}/reports/id/:reportId/`,
    basePdf: `${BASE}${VERSION}${PEOPLE_LOOK}/data/base-pdf/:reportId/`,
    customPdf: `${BASE}${VERSION}${PEOPLE_LOOK}/data/custom-pdf/:reportId/`,
    templates: `${BASE}${VERSION}${PEOPLE_LOOK}/templates/type/:typeId/`,
    createReport: `${BASE}${VERSION}${PEOPLE_LOOK}/templates/create-report/:templateId/`,
    createReportOld: `${BASE}${VERSION}${PEOPLE_LOOK}/data/report-file/:reportId/`,
    reportDeactivate: `${BASE}${VERSION}${PEOPLE_LOOK}/reports/deactivate/:reportId/`,
    reportPublish: `${BASE}${VERSION}${PEOPLE_LOOK}/reports/publish/:reportId/`,
    editTable: `${BASE}${VERSION}${PEOPLE_LOOK}/data/edit_graph_data/:graphId/`,
    widgetsList: `${BASE}${VERSION}${PEOPLE_LOOK}/reports/graph/widget/:graphId`,
    widgetData: `${BASE}${VERSION}${PEOPLE_LOOK}/data/widget/:wigetId/:recordId`,
    initFilters: `${BASE}${VERSION}${PEOPLE_LOOK}/reports/initial-filters/:reportId/`,
    uploadFileTable: `${BASE}${VERSION}${PEOPLE_LOOK}/data/report_data_file/:reportId/:dataRecordId`,
    uploadImage: `${BASE}${VERSION}${PEOPLE_LOOK}/data/report_graph_image/:graphId`,
    editGraphData: `${BASE}${VERSION}${PEOPLE_LOOK}/reports/editing/graph/:graphId`,
    reportFields: `${BASE}${VERSION}${PEOPLE_LOOK}/reports/editing/report/:reportId/fields`,
    reportPictures: `${BASE}${VERSION}${PEOPLE_LOOK}/data/report_images`,
    seriesList: `${BASE}${VERSION}${PEOPLE_LOOK}/reports/editing/graph/:graphId/series`,
    modifiersList: `${BASE}${VERSION}${PEOPLE_LOOK}/reports/editing/graph/:graphId/modifier`,
    createGraph: `${BASE}${VERSION}${PEOPLE_LOOK}/reports/editing/graph`,
    deleteSeries: `${BASE}${VERSION}${PEOPLE_LOOK}/reports/editing/series/:seriesId`,
    crudModifier: `${BASE}${VERSION}${PEOPLE_LOOK}/reports/editing/modifier/:modifierId`,
    createSeries: `${BASE}${VERSION}${PEOPLE_LOOK}/reports/editing/graph/:graphId/series`,
    createPage: `${BASE}${VERSION}${PEOPLE_LOOK}/reports/editing/page`,
    createSection: `${BASE}${VERSION}${PEOPLE_LOOK}/reports/editing/section`,
    createTemplate: `${BASE}${VERSION}${PEOPLE_LOOK}/templates/create-template/:reportId/`,
    crudSection: `${BASE}${VERSION}${PEOPLE_LOOK}/reports/editing/section/:sectionId`,
    crudPage: `${BASE}${VERSION}${PEOPLE_LOOK}/reports/editing/page/:pageId`,
    createBlankReport: `${BASE}${VERSION}${PEOPLE_LOOK}/reports/:companyId/create/`,
    blankCsv: `${BASE}${VERSION}${PEOPLE_LOOK}/data/report-create-file/:reportId/`,
    reportTableAlias: `${BASE}${VERSION}${PEOPLE_LOOK}/data/report-table-alias/:reportId/`,
    csvFields: `${BASE}${VERSION}${PEOPLE_LOOK}/data/:companyId/report/:reportId/fields/`,
    matchFields: `${BASE}${VERSION}${PEOPLE_LOOK}/data/1/report/:reportId/edit-table-aliases/`,
    editFields: `${BASE}${VERSION}${PEOPLE_LOOK}/reports/editing/report/:reportId/fields`,
    dltTemplate: `${BASE}${VERSION}${PEOPLE_LOOK}/templates/delete-template/:templateId`,
  },
  assetLook: {
    categories: `${BASE}${VERSION}${ASSET_LOOK}/categories/:companyId/`,
    products: `${BASE}${VERSION}${ASSET_LOOK}/products/:companyId/`,
    assets: `${BASE}${VERSION}${ASSET_LOOK}/items/:companyId/:productId/`,
    allAssets: `${BASE}${VERSION}${ASSET_LOOK}/items/:companyId/all`,
    initFilters: `${BASE}${VERSION}${ASSET_LOOK}/initial-filters/:companyId/`,
    initModifiedFilters: `${BASE}${VERSION}${ASSET_LOOK}/initial-filters/:companyId/`,
    targetFilters: `${BASE}${VERSION}${ASSET_LOOK}/initial-target-filters/:companyId/`,
    summaryFields: `${BASE}${VERSION}${ASSET_LOOK}/summary-group-by-fields/:companyId/`,
    summary: `${BASE}${VERSION}${ASSET_LOOK}/product-summary/:companyId/:productId/`,
    pdf: `${BASE}${VERSION}${ASSET_LOOK}/pdf/:companyId/`,
    pdfItem: `${BASE}${VERSION}${ASSET_LOOK}/pdf-id/:pdfId/`,
    locations: `${BASE}${VERSION}${ASSET_LOOK}/locations/:companyId/`,
    tags: `${BASE}${VERSION}/company/:companyId/asset-tags/`,
    edit: `${BASE}${VERSION}/asset-look/items/:companyId/edit/`,
    basketSummary: `${BASE}${VERSION}${ASSET_LOOK}/orders/basket-summary/:companyId/:buildingId/`,
    orders: `${BASE}${VERSION}${ASSET_LOOK}/orders/`,
    orderList: `${BASE}${VERSION}${ASSET_LOOK}/orders/list/:companyId/`,
    orderEdit: `${BASE}${VERSION}${ASSET_LOOK}/orders/:orderId/edit/`,
    orderCancel: `${BASE}${VERSION}${ASSET_LOOK}/orders/:orderId/cancel/`,
    upcomingDelivery: `${BASE}${VERSION}${ASSET_LOOK}/orders/:companyId/arrival-order_create/`,
  },
  liveLook: {
    doorSensorsInitData: `${BASE}${VERSION}${LIVE_LOOK}/:companyId/door-sensor/init-data/`,
    commonData: `${BASE}${VERSION}${LIVE_LOOK}/:companyId/location/init-data/`,
    commonDataTmp: `${BASE}${VERSION}${LIVE_LOOK}/:companyId/location/init-data-tmp/`,
    doorsOverTime: `${BASE}${VERSION}${LIVE_LOOK}/:companyId/door-sensor/occupancy/`,
    doorsDaily: `${BASE}${VERSION}${LIVE_LOOK}/:companyId/door-sensor/weekdays/`,
    doorsByBuilding: `${BASE}${VERSION}${LIVE_LOOK}/:companyId/door-sensor/building/:buildingId/`,
    deskByBuilding: `${BASE}${VERSION}${LIVE_LOOK}/:companyId/desk/building/:buildingId/`,
    desksByDivision: `${BASE}${VERSION}${LIVE_LOOK}/:companyId/desk/division/:buildingId/`,
    floorsLiveOcc: `${BASE}${VERSION}${LIVE_LOOK}/:companyId/door-sensor/live-data/`,
    meetsOverTime: `${BASE}${VERSION}${LIVE_LOOK}/:companyId/meet/occupancy/`,
    breakoutOverTime: `${BASE}${VERSION}${LIVE_LOOK}/:companyId/break/occupancy/`,
    breakoutBySubtype: `${BASE}${VERSION}${LIVE_LOOK}/:companyId/break/by-subtype/`,
    meetingBySubtype: `${BASE}${VERSION}${LIVE_LOOK}/:companyId/meet/by-subtype/`,
    meetingBySize: `${BASE}${VERSION}${LIVE_LOOK}/:companyId/meet/by-size/`,
    breakBySize: `${BASE}${VERSION}${LIVE_LOOK}/:companyId/break/by-size/`,
    meetsDataboxes: `${BASE}${VERSION}${LIVE_LOOK}/:companyId/meet/data-boxes/`,
    breakDataboxes: `${BASE}${VERSION}${LIVE_LOOK}/:companyId/break/data-boxes/`,
    ocuupancyDataboxes: `${BASE}${VERSION}${LIVE_LOOK}/:companyId/door-sensor/data-boxes/`,
    combinedOcuupancyDataboxes: `${BASE}${VERSION}${LIVE_LOOK}/:companyId/desk-and-break/data-boxes/`,
    deskDataboxes: `${BASE}${VERSION}${LIVE_LOOK}/:companyId/desk/data-boxes/`,
    deskOverTime: `${BASE}${VERSION}${LIVE_LOOK}/:companyId/desk/occupancy/`,
    deskBySubtype: `${BASE}${VERSION}${LIVE_LOOK}/:companyId/desk/by-subtype/`,
    deskPeakOccupancyDayUsage: `${BASE}${VERSION}${LIVE_LOOK}/:companyId/desk/peak-occupancy-day-usage/`,
    graphsText: `${BASE}${VERSION}${LIVE_LOOK}/:companyId/page-text/`,
    desksByDivisionSummary: `${BASE}${VERSION}${LIVE_LOOK}/:companyId/desk/division/usage/`,
    pdfData: `${BASE}${VERSION}${LIVE_LOOK}/pdf/:pdfId`,
    pinnedPdf: `${BASE}${VERSION}${LIVE_LOOK}/pdf/:pdfId/pin`,
    pdfList: `${BASE}${VERSION}${LIVE_LOOK}/:companyId/pdf/public`,
    pdfCreate: `${BASE}${VERSION}${LIVE_LOOK}/:companyId/pdf/`,
    archives: `${BASE}${VERSION}${LIVE_LOOK}/:companyId/archive`,
    privatePdfList: `${BASE}${VERSION}${LIVE_LOOK}/:companyId/pdf/private`,
    standardPdfList: `${BASE}${VERSION}${LIVE_LOOK}/:companyId/pdf/standard`,
    deskAndBreak: `${BASE}${VERSION}${LIVE_LOOK}/:companyId/desk-and-break/division/usage/`,
    deskVariables: `${BASE}${VERSION}${LIVE_LOOK}/:companyId/desk/variables/`,
    combinedTeamZoneOccupancy: `${BASE}${VERSION}${LIVE_LOOK}/:companyId/desk-and-break/occupancy/`,
    combinedOccupancyVariables: `${BASE}${VERSION}${LIVE_LOOK}/:companyId/desk-and-break/variables/`,
  },
};

export default ENDPOINTS;
