/* eslint-disable camelcase */
import React, { useRef, useState } from 'react';
import { Typography, Button, TextField, Box, Checkbox, FormControlLabel, ButtonBase } from '@material-ui/core';
// import { Skeleton } from '@material-ui/lab';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { ENDPOINTS } from 'modules/shared/constants';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import { isLogged } from 'utils';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { getCompanies } from 'modules/auth/selectors';
import { useSharedSelector } from 'modules/shared/hooks';
import { isAdmin, isClient, isPartner } from 'modules/shared/utils';
import theme from './companyInfo.module.scss';
import { COMPANY_SECTOR_NAMES, COMPANY_SHORT_SECTOR_NAMES } from './companyinfo.constants';

const CompanyInfoForm = ({ user, onClose, onChange }) => {
  const [portalSections] = useSharedSelector(['portalSection']);

  const inputReportLogo = useRef();
  const inputReportLogoColor = useRef();
  const inputReportSectionImage = useRef();
  const inputReportClosingImage = useRef();

  const [reportLogo, setReportLogo] = useState('');
  const [reportLogoColor, setReportLogoColor] = useState('');
  const [reportSectionImage, setReportSectionImage] = useState('');
  const [reportClosingImage, setReportClosingImage] = useState('');

  //   const {
  //     company,
  //     email,
  //     id: userId,
  //     first_name,
  //     is_edit_assets_permission,
  //     is_manage_orders_permission,
  //     landing_start_page,
  //     last_name,
  //     partner_company,
  //     people_look_editing_rights,
  //     role,
  //   } = user;

  //   const isPartnerRole = isPartner(role);
  //   const isClientRole = isClient(role);
  //   const getCompaniesList = isPartnerRole || isClientRole ? [company]?.flat() : companies;
  //   const getPartnerCompaniesList = isPartnerRole || isClientRole ? [partner_company]?.flat() : partnerCompanies;

  const [errorResultName, setErrorResultName] = useState(false);
  const [errors, setErrors] = useState({
    isError: false,
    errorMsg: [],
  });

  const validationSchema = Yup.object().shape({
    company_name: Yup.string().required('*Name required'),
    company_sector_name: Yup.string().required('*Order required'),
    company_short_sector_name: Yup.string().required('*Order required'),
  });

  const editInitialValues = {
    company_name: '',
    company_sector_name: '',
    company_short_sector_name: '',
    portal_sections: [],
    landing_start_page: '',
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: editInitialValues,
    validationSchema,
    onSubmit: (values) => {
      console.log('values', values);
      // const result = JSON.stringify(getValues, (k, val) => (val === '' ? null : val));
      //   const url = ENDPOINTS.auth.updateUser.replace(':user_id', userId);
      //   const data = new FormData();
      //   Object.entries(getValues).map(([key, value]) => {
      //     return data.append(key, value);
      //   });
      //   const config = {
      //     method: 'put',
      //     url,
      //     headers: {
      //       Authorization: `Token ${isLogged()}`,
      //       'Content-Type': 'application/json',
      //     },
      //     data,
      //   };
      //   axios(config)
      //     .then(() => {
      //       onClose();
      //       window.location.reload(false);
      //     })
      //     .catch(({ response }) => {
      //       setErrors({ isError: true, errorMsg: response?.data });
      //     });
    },
  });

  return (
    // <Choose>
    //   <When condition={isModifierLoading}>
    //     <Skeleton variant="rect" height="100%" width="100%" />
    //   </When>
    //   <Otherwise>
    <div className={theme.container}>
      <div className={theme.wrapper}>
        <div className={theme.header}>
          <Typography variant="h5" className={theme.title}>
            Edit Company Information
          </Typography>
        </div>
        <div className={theme.formWrapper}>
          <form className={theme.formContainer} onSubmit={formik.handleSubmit}>
            <Box>
              <TextField
                fullWidth
                id="company_name"
                name="company_name"
                label="Company name"
                placeholder="Enter company name"
                value={formik.values.company_name}
                onChange={formik.handleChange}
                variant="outlined"
              />
              <Typography variant="body2" className={theme.error}>
                {formik.errors.company_name || errors.errorMsg?.company_name}
              </Typography>
            </Box>
            <Box sx={{ mt: 2, mb: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="company_sector_name">Company sector name</InputLabel>
                <Select
                  labelId="company_sector_name"
                  id="company_sector_name"
                  name="company_sector_name"
                  label="Company sector name"
                  value={formik.values.company_sector_name}
                  onChange={formik.handleChange}
                >
                  {COMPANY_SECTOR_NAMES.map(({ id, name: sectorName }) => (
                    <MenuItem key={id} value={id}>
                      {sectorName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box>
              <FormControl fullWidth>
                <InputLabel id="company_short_sector_name">Company short sector name</InputLabel>
                <Select
                  labelId="company_short_sector_name"
                  id="company_short_sector_name"
                  name="company_short_sector_name"
                  label="Company short sector name"
                  value={formik.values.company_short_sector_name}
                  onChange={formik.handleChange}
                >
                  {COMPANY_SHORT_SECTOR_NAMES.map(({ id, name: shortSectorName }) => (
                    <MenuItem key={id} value={id}>
                      {shortSectorName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <div className={theme.divider}>
              <Typography variant="body1" className={theme.dividerText}>
                Additional info
              </Typography>
            </div>
            <Box sx={{ mt: 2, mb: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="portal_sections">Portal sections</InputLabel>
                <Select
                  multiple
                  labelId="portal_sections"
                  id="portal_sections"
                  name="portal_sections"
                  label="Portal sections"
                  value={formik.values?.portal_sections}
                  onChange={formik.handleChange}
                >
                  {portalSections.map(({ name: sectionName }, index) => (
                    <MenuItem key={index} value={sectionName}>
                      {sectionName}
                    </MenuItem>
                  ))}
                </Select>
                <Typography variant="body2" className={theme.error}>
                  {errors.errorMsg?.portal_sections}
                </Typography>
              </FormControl>
            </Box>
            <Box>
              <FormControl fullWidth>
                <InputLabel id="landing_start_page">Landing start page</InputLabel>
                <Select
                  labelId="landing_start_page"
                  id="landing_start_page"
                  name="landing_start_page"
                  label="Landing start page"
                  value={formik.values?.landing_start_page}
                  onChange={formik.handleChange}
                >
                  {portalSections.map(({ name: sectionName }, index) => (
                    <MenuItem key={index} value={sectionName}>
                      {sectionName}
                    </MenuItem>
                  ))}
                </Select>
                <Typography variant="body2" className={theme.error}>
                  {errors.errorMsg?.landing_start_page}
                </Typography>
              </FormControl>
            </Box>
            <Box sx={{ mt: 2, mb: 2 }}>
              <Button fullWidth component="label" color="primary" variant="outlined">
                Report logo
                <input
                  type="file"
                  id="image"
                  hidden
                  // disabled={isLoading}
                  name="image"
                  accept="image/*"
                  multiple={false}
                  onChange={() => setReportLogo(inputReportLogo.current.files[0])}
                  ref={inputReportLogo}
                />
              </Button>
              <Typography variant="body2">{reportLogo?.name}</Typography>
            </Box>
            <Box>
              <Button fullWidth component="label" color="primary" variant="outlined">
                Report logo color
                <input
                  type="file"
                  id="image"
                  hidden
                  // disabled={isLoading}
                  name="image"
                  accept="image/*"
                  multiple={false}
                  onChange={() => setReportLogoColor(inputReportLogoColor.current.files[0])}
                  ref={inputReportLogoColor}
                />
              </Button>
              <Typography variant="body2">{reportLogoColor?.name}</Typography>
            </Box>
            <Box sx={{ mt: 2, mb: 2 }}>
              <Button fullWidth component="label" color="primary" variant="outlined">
                Report section image
                <input
                  type="file"
                  id="image"
                  hidden
                  // disabled={isLoading}
                  name="image"
                  accept="image/*"
                  multiple={false}
                  onChange={() => setReportSectionImage(inputReportSectionImage.current.files[0])}
                  ref={inputReportSectionImage}
                />
              </Button>
              <Typography variant="body2">{reportSectionImage?.name}</Typography>
            </Box>
            <Box>
              <Button fullWidth component="label" color="primary" variant="outlined">
                Report closing image
                <input
                  type="file"
                  id="image"
                  hidden
                  // disabled={isLoading}
                  name="image"
                  accept="image/*"
                  multiple={false}
                  onChange={() => setReportClosingImage(inputReportClosingImage.current.files[0])}
                  ref={inputReportClosingImage}
                />
              </Button>
              <Typography variant="body2">{reportClosingImage?.name}</Typography>
            </Box>
            <div className={theme.divider}>
              <Typography variant="body1" className={theme.dividerText}>
                Live Look variables
              </Typography>
            </div>
            <Box sx={{ mt: 2, mb: 2 }}>
              <TextField
                fullWidth
                type="number"
                id="working_hours_from"
                name="working_hours_from"
                label="Working hours from"
                placeholder="Enter working hours from:"
                value={formik.values.working_hours_from}
                onChange={formik.handleChange}
                variant="outlined"
              />
            </Box>
            <Box>
              <TextField
                fullWidth
                type="number"
                id="working_hours_to"
                name="working_hours_to"
                label="Working hours to"
                placeholder="Enter working hours to"
                value={formik.values.working_hours_to}
                onChange={formik.handleChange}
                variant="outlined"
              />
            </Box>
            <div className={theme.btnContainer}>
              <Button className={theme.sbmtBtn} color="primary" variant="outlined" type="submit">
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
    //   </Otherwise>
    // </Choose>
  );
};

export default CompanyInfoForm;
