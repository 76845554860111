import findActiveCompany from 'utils/findActiveCompany';
import getUserCompanies from 'utils/getUserCompanies';
import { userRoles } from 'modules/auth/constants';

// eslint-disable-next-line consistent-return
const getActiveCompany = ({ auth: { user, currentCompany } }) => {
  const companies = getUserCompanies(user);
  const activeCompany = currentCompany || findActiveCompany(user);

  // eslint-disable-next-line default-case
  switch (user.role) {
    case userRoles.admin:
      return companies.find((company) => company.id === activeCompany);
    case userRoles.partner:
      return {
        ...companies.find((company) => company.id === activeCompany),
        logo: user.partner_company.logo,
      };
    case userRoles.client:
      return user.company;
    case userRoles.company_admin:
      return user.company;
    case userRoles.parter_admin:
      return user.company;
  }
};

export default getActiveCompany;
