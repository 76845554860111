import { Typography } from '@material-ui/core';
import React from 'react';
import { ROLE_NAMES } from './userRow.constants';

import theme from './userRow.module.scss';

const getField = (key, value) => {
  if (key === 'role') return ROLE_NAMES[value];
  return value;
};

const UserRow = ({ user }) => {
  const getUser = {
    email: user.email,
    first_name: user.first_name,
    last_name: user.last_name,
    role: user.role,
    group: user.group,
  };

  return (
    <div className={theme.row}>
      {Object.entries(getUser).map(([key, value], index) => {
        return (
          <div key={index} className={theme.object}>
            <Typography style={{ fontSize: '13px' }}>{getField(key, value)}</Typography>
          </div>
        );
      })}
    </div>
  );
};

export default UserRow;
