import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import axios from 'axios';
import { isEmpty } from 'lodash';

import { withUser } from 'hocs';
import { REQUESTS } from '_constants';
import { getData, summaryPageMocked as MOCKED_DATA } from 'services';
import { requestStatuses } from 'modules/shared/constants';

import {
  PageHeader,
  StaticSidebar,
  ModalWindow,
  FiltersInBlockButton,
  StatsTable,
  RoundArrowButton,
  Loader,
  BuildingsMap,
} from 'components';
import { NavBar, TextPlaceholder } from 'modules/shared/components';

import {
  isLogged,
  findActiveCompany,
  getUserCompanies,
  defineCompanyNameAndLogo,
  withoutSecondFactorAuthLogOut,
} from 'utils';

import toggleMap from 'actions/toggleMap';
import invokeModal from 'actions/invokeModal';
import { getPropertyNamesMap } from 'modules/shared/selectors';
import useSummaryPageReducer from 'components/Pages/SummaryPage/hooks/useSummaryPageReducer';
import {
  setSummaryPageData,
  applyFilter,
  resetFilter,
  SET_SUMMARY_PAGE_DATA,
} from 'components/Pages/SummaryPage/actions';
import { Button } from '@material-ui/core';
import Graphs from 'components/Pages/SummaryPage/components/Graphs';
import Filters from 'components/Pages/SummaryPage/components/Filters';
import SummaryDataCards from 'components/Pages/SummaryPage/components/SummaryDataCards';
import './SummaryPage.scss';

const {
  base,
  version,
  summary: {
    databoxes: databoxesRequest,
    buildings,
    graphs: { property, division, propertyPercent, divisionPercent },
  },
} = REQUESTS;

// TODO: Add request failure handling
// TODO: Rewrite from scratch!
const SummaryPage = ({ history, user, currentCompany, setCurrentCompany }) => {
  const { propertyNamesMap } = useSelector(getPropertyNamesMap);
  const [state, dispatch] = useSummaryPageReducer();

  const {
    showMap,
    showModal,
    requestStatus,
    appliedFilter,
    data: { tableRows, databoxes, buildingsConfig },
  } = state;

  const activeCompany = currentCompany || findActiveCompany(user);
  const token = isLogged();

  useEffect(() => {
    dispatch({ type: SET_SUMMARY_PAGE_DATA.pending });

    const graphRequest = [databoxesRequest, buildings, property, propertyPercent, divisionPercent, division]
      .map((request) => `${base}${version}/company/${activeCompany}${request}${appliedFilter}`)
      .map((url) => getData(url, token));

    axios
      .all([...graphRequest])
      .then((data) => dispatch(setSummaryPageData(...data, propertyNamesMap)))
      .catch((err) => {
        withoutSecondFactorAuthLogOut(err, history);
        dispatch({ type: SET_SUMMARY_PAGE_DATA.failure });
      });
  }, [activeCompany, appliedFilter, dispatch, history, propertyNamesMap, token]);

  const companies = getUserCompanies(user);
  const [companyName, logoUrl] = defineCompanyNameAndLogo(companies, user, activeCompany);
  console.log('companyName', companyName, 'logoUrl', logoUrl);
  const isNoData = isEmpty(buildingsConfig);

  return (
    <Choose>
      <When condition={isEmpty(state.data)}>
        <Loader />
      </When>
      <Otherwise>
        <div className={cn('SummaryPage', { 'no-map': !showMap })}>
          <If condition={requestStatus !== requestStatuses.success}>
            <Loader />
          </If>
          <div className={cn('summary-page', { blured: requestStatus !== requestStatuses.success })}>
            <div className="summary-nav">
              <StaticSidebar
                items={companies}
                onItemClick={setCurrentCompany}
                currentItem={activeCompany}
                clientName={companyName}
                logoUrl={logoUrl}
                hideDetails={isNoData}
              />
            </div>
            <div className="summary-content-scroll">
              <div className="summary-content">
                <div className="nav-menu">
                  <NavBar />
                </div>
                <Choose>
                  <When condition={isNoData}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        marginTop: '400px',
                      }}
                    >
                      <TextPlaceholder noData />
                      <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        className="resetBtn"
                        onClick={() => dispatch(resetFilter())}
                      >
                        RESET
                      </Button>
                    </div>
                  </When>
                  <Otherwise>
                    <RoundArrowButton
                      additionalClass={cn({ 'invert-arrow': !showMap })}
                      onClick={() => dispatch(toggleMap())}
                    />
                    <div className="map-container">
                      <PageHeader title="Property Summary" />
                      <BuildingsMap buildingsData={buildingsConfig} />
                    </div>
                    <div className="content-container">
                      <div className="content-container-inner">
                        <div className="content-container-header">
                          <PageHeader title="Property Summary" />
                          <SummaryDataCards databoxes={databoxes} />
                        </div>
                        <ModalWindow
                          OpenButton={FiltersInBlockButton}
                          withCloseButton={false}
                          onOpen={() => dispatch(invokeModal())}
                          onClose={() => dispatch(invokeModal())}
                          isOpen={showModal}
                          isCloseOnOutClick
                          modalStyles={{ width: '998px', margin: '0 auto' }}
                        >
                          <Filters
                            activeCompany={activeCompany}
                            applyFilters={(selectedFilters) => dispatch(applyFilter(selectedFilters))}
                          />
                        </ModalWindow>

                        <Graphs state={state} dispatch={dispatch} />

                        <div className="table-section">
                          <h2 className="summary-title">Properties list</h2>
                          <StatsTable rows={tableRows} headerLabels={MOCKED_DATA.table.headers} />
                        </div>
                      </div>
                    </div>
                  </Otherwise>
                </Choose>
              </div>
            </div>
          </div>
        </div>
      </Otherwise>
    </Choose>
  );
};

export default withUser(SummaryPage);
